export default {
    return: 'Volver',
    name: 'Nombre',
    preferredLanguage: 'Lenguaje de preferencia',
    languagePlaceHolder: 'Selecciona un lenguage',
    noLanguage: 'Lenguaje no encontrado',
    noLanguages: 'Lenguajes no encontrado',
    information: 'Información',
    description: 'Descripción',
    create: 'Crear',
    module: 'Módulo',
    actions: 'Acciones',
    activeText: 'Activo',
    inactiveText: 'Inactivo',
    details: 'Detalles',
    status: 'Estado',
    greeting: 'Hola,',
    cancel: 'Cancelar',
    edit: 'Editar',
    address: 'Dirección',
    category: 'Categoría',
    subcategory: 'Subcategoría',
    categoryPlaceHolder: 'Selecciona una categoría',
    subcategoryPlaceHolder: 'Selecciona una subcategoría',
    noCategory: 'Categoría no encontrada',
    noCategories: 'Categorías no encontradas',
    noSubcategory: 'Subcategoría no encontrada',
    noSubcategories: 'Subcategorías no encontradas',
    warning: 'Advertencia',
    success: '¡Éxito!',
    unknown: 'Desconocido',
    genericError: 'Ha ocurrido un error al',
    update: 'actualizar',
    loadingLabel: 'Cargando Información...',
    enableEdit: 'Habilitar para editar',
    editWarning: 'Debes habilitar para editar si quieres modificar este campo',
    confirmationMessage: '¿Está seguro que desea continuar?',
    finalized: 'Finalizado',
};
