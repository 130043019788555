export default {
    user: 'Usuario',
    users: 'Usuarios',
    newUser: 'Agregar nuevo usuario',
    userRestorePassword: 'Restaurar contraseña',
    noUserCompany: 'Este usuario no tiene organizaciones asociadas.',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    getUserError: 'Ha ocurrido un error al obtener el usuario',
    createUserError: 'Ha ocurrido un error al crear el usuario',
    createUserSuccess: 'Usuario creado correctamente',
    updateUserError: 'Ha ocurrido un error al actualizar el usuario',
    updateUserSuccess: 'Usuario actualizado correctamente',
    getUsersError: 'Ha ocurrido un error al obtener los usuarios: ',
    deleteUserError: 'Ha ocurrido un error al eliminar el usuario! ',
    deleteUserSuccess: 'Usuario eliminado exitosamente',
    emailConfirmationMessage: 'Se enviará un correo de recuperación de contraseña a ',
    sendRecoveryEmailError: 'Ha ocurrido un error al enviar el correo de restauración!',
    sendRecoveryEmailSuccess: '¡El correo de restauración de contraseña ha sido enviado!',
    confirmDeleteUser: '¿Está seguro que desea eliminar el usuario?',
}
