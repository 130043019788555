export default {
    rol: 'Rol',
    roles: 'Roles',
    newRol: 'Agregar nuevo rol',
    userRoles: 'Roles de Usuario',
    rolName: 'Nombre del rol',
    rolNamePlaceHolder: 'Ingresa el nombre del rol',
    rolDescriptionPlaceHolder: 'Ingresa la descripcion del rol',
    rolPermissions: 'Permisos',
    rolPermission: 'Permiso',
    rolPlaceHolder: 'Selecciona un rol',
    noRol: 'Rol no encontrado',
    noRoles: 'Roles no encontrados',
    rolesError: 'Ha ocurrido un error al obtener los roles: ',
    deleteRoleError: 'Ha ocurrido un error al eliminar el rol',
    roleDeletedSuccess: 'Rol eliminado exitosamente',
    roleError: 'Ha ocurrido un error al obtener el rol:',
    roleUpdated: 'Rol actualizado correctamente!',
    roleCreated: 'Rol creado correctamente!',
    roleUpdateError: 'Error al actualizar el rol!',
    roleCreateError: 'Error al crear el rol!',
};
