export default {
    threat: 'threat',
    threats: 'threats',
    newThreat: 'Add new threat',
    actor: 'Actor',
    actorPlaceHolder: 'Select an effect',
    noActor: 'Actor not found',
    noActors: 'Actors not found',
    reason: 'Reason',
    reasonPlaceHolder: 'Select a Reason',
    noReason: 'Reason not found',
    noReasons: 'Reasons not found',
    example: 'Example',
    code: 'Code',
    codePlaceHolder: 'Threat Code',
    createThreat: 'Create threat',
    getThreatsError: 'An error occurred while fetching threats: ',
    createThreatError: 'An error occurred while creating threat: ',
    createThreatSuccess: 'Threat created successfully',
};
