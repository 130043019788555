import { createRouter, createWebHistory } from 'vue-router';
import companiesRoutes from './companies';
import authRoutes from './auth';
import threatsRoutes from './threats';
import assetsRoutes from './assets';
import usersRoutes from './users';
import rolesRoutes from './roles';
import impactsRoutes from './impacts';
import scopesRoutes from './scopes';

const routes = [

    {
        path: '/',
        name: 'LandingPage',
        component: () => import('../views/auth/LoginComponent.vue'),
    },

    {
        path: '/home',
        name: 'HomePage',
        component: () => import('../views/HomePage.vue'),
    },

    {
        path: '/select-company',
        name: 'SelectCompanyPage',
        component: () => import('../views/auth/SelectCompanyComponent.vue'),
    },

    ...companiesRoutes,
    ...authRoutes,
    ...threatsRoutes,
    ...assetsRoutes,
    ...usersRoutes,
    ...rolesRoutes,
    ...impactsRoutes,
    ...scopesRoutes

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;