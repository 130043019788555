export default {
    user: 'User',
    users: 'Users',
    newUser: 'Add new user',
    userRestorePassword: 'Restore password',
    noUserCompany: 'This user has no associated companies.',
    lastName: 'Last Name',
    email: 'Email Address',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    phone: 'Phone',
    getUserError: 'An error occurred while fetching user',
    createUserError: 'An error occurred while creating user',
    createUserSuccess: 'User created successfully',
    updateUserError: 'An error occurred while updating user',
    updateUserSuccess: 'User updated successfully',
    getUsersError: 'An error occurred while fetching users: ',
    deleteUserError: 'An error occurred while deleting user! ',
    deleteUserSuccess: 'User deleted successfully',
    emailConfirmationMessage: 'A password recovery email will be sent to ',
    sendRecoveryEmailError: 'An error occurred while sending the recovery email!',
    sendRecoveryEmailSuccess: 'The password recovery email has been sent!',
    confirmDeleteUser: 'Are you sure you want to delete user?'
};
