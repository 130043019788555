import register from './labels/register';
import login from './labels/login';
import general from './labels/general';
import footer from './labels/footer';
import sideMenu from './labels/sideMenu';
import users from './labels/users';
import companies from './labels/companies';
import country from './labels/country';
import roles from './labels/roles';
import cities from './labels/cities';
import states from './labels/states';
import threats from './labels/threats';
import assets from './labels/assets';
import impacts from './labels/impacts';
import scopes from './labels/scopes';

export default {
    ...register,
    ...login,
    ...general,
    ...footer,
    ...sideMenu,
    ...users,
    ...roles,
    ...companies,
    ...country,
    ...cities,
    ...states,
    ...threats,
    ...assets,
    ...impacts,
    ...scopes,
};