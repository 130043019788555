export default {
    rol: 'Rol',
    roles: 'Roles',
    newRol: 'Add new rol',
    userRoles: 'User Roles',
    rolName: 'Role name',
    rolNamePlaceHolder: 'Enter role name',
    rolDescriptionPlaceHolder: 'Enter role description',
    rolPermissions: 'Permissions',
    rolPermission: 'Permission',
    rolPlaceHolder: 'Select a rol',
    noRol: 'Rol not found',
    noRoles: 'Rols not found',
    rolesError: 'An error occurred while fetching roles:',
    deleteRoleError: 'An error occurred while deleting role',
    roleDeletedSuccess: 'Role deleted successfully',
    roleError: 'An error occurred while fetching role: ',
    roleUpdated: 'Role updated successfully!',
    roleCreated: 'Role created successfully!',
    roleUpdateError: 'Error updating role!',
    roleCreateError: 'Error creating rol!',
};
