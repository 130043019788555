import { TRANSACTION_TYPES } from '../utils/constants';

const assetsRoutes = [

    {
        path: '/assets',
        name: 'AssetsPage',
        component: () => import('@/views/assets/AssetsComponent.vue'),
    },
    {
        path: '/assets/create',
        name: 'AssetsCreatePage',
        component: () => import('@/views/assets/AssetsManagerComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE }
    },
    {
        path: '/assets/criticality',
        name: 'AssetCriticalityPage',
        component: () => import('@/views/assets/AssetCriticalityComponent.vue'),
    },
    {
        path: '/assets/edit/:assetId',
        name: 'AssetsEditPage',
        component: () => import('@/views/assets/AssetsManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.EDIT, assetId: route.params.assetId })
    },
    {
        path: '/assets/show/:assetId',
        name: 'AssetsShowPage',
        component: () => import('@/views/assets/AssetsManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.SHOW, assetId: route.params.assetId })
    },

];

export default assetsRoutes;