export default {
    recoveryPassword: 'Recover password',
    login: 'Log in',
    loginWithGoogle: 'Sign in with Google',
    loginWithOutlook: 'Sign in with Outlook',
    notAccount: 'Don\'t you have an account?',
    notAccountRegister: 'Register',
    loginError: 'An error occurred while logging in',
    resetPasswordError: 'An error occurred while resetting the password:',
};
