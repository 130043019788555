export default {
    name: {
        invalid: "Name only allow letters",
        required: "Name is required"
    },
    lastName: {
        invalid: "Last name only allow letters",
        required: "Last Name is required"
    },
    country: {
        required: "Country is required"
    },
    language: {
        required: "Language is required"
    },
    email: {
        email: "Invalid email address",
        required: "Email is required"
    },
    password: {
        min: "Password must be at least {min} characters",
        required: "Password is required"
    },
    passwordConfirm: {
        match: "Passwords must match",
        required: "Password confirmation is required"
    }
};