import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './assets/css/tailwind.css';
import './assets/css/main.css';
import 'gridjs/dist/theme/mermaid.min.css';
import 'mingcute_icon/font/Mingcute.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import i18n from './i18n';
import VueCookies from 'vue-cookies';
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persistedstate';

const app = createApp(App)
const pinia = createPinia();
pinia.use(piniaPersist);

app.use(pinia);

app.use(i18n);
app.use(router)
app.use(VueCookies);

app.mount('#app')