import enValidation from '@/dictionary/en/validations';
import enLabels from '@/dictionary/en/labels';

import esValidation from '@/dictionary/es/validations';
import esLabels from '@/dictionary/es/labels';

const validationMessages = {
    en: {
        validation: enValidation,
        labels: enLabels
    },
    es: {
        validation: esValidation,
        labels: esLabels
    }
};

export default validationMessages;
