export default {
    name: {
        invalid: "El nombre solo puede contener letras",
        required: "El nombre es obligatorio"
    },
    lastName: {
        invalid: "El apellido solo puede contener letras",
        required: "El apellido es obligatorio"
    },
    country: {
        required: "El pais es obligatorio"
    },
    language: {
        required: "El lenguaje es obligatorio"
    },
    email: {
        email: "Dirección de correo electrónico inválida",
        required: "El correo electrónico es obligatorio"
    },
    password: {
        min: "La contraseña debe tener al menos {min} caracteres",
        required: "La contraseña es obligatoria"
    },
    passwordConfirm: {
        match: "Las contraseñas deben coincidir",
        required: "La confirmación de la contraseña es obligatoria"
    }
};